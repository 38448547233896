import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@ui/button";
import {
  LocalizeWrapper,
  LocalizeInner,
  LocalizeContentbox,
  LocalizeContentboxInner,
  LocalizeIconwrap,
} from "./style";
const Text = React.lazy(() => import('@ui/text'));
const Image = React.lazy(() => import('@ui/image'));
const Heading = React.lazy(() => import('@ui/heading'));
const Anchor = React.lazy(() => import('@ui/anchor'));
const SectionTitle = React.lazy(() => import('@ui/section-title'));

const LocalizeGrid = React.memo(({ data, layout }) => {
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <LocalizeWrapper layout={layout}  id={data.section}>
      <LocalizeInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LocalizeContentbox>
                {data &&
                  data?.items.map((el, index) => (
                      <Anchor key={index} path={el.path} target="_blank">
                        <LocalizeContentboxInner>
                          <LocalizeIconwrap className="flipFront">
                            <Image
                              src={el.images[0].src}
                              alt={el.images[0]?.alt || "Icon"}
                            />
                            <Heading as="h4">{el.title}</Heading>
                            <Text>{el.description}</Text>
                          </LocalizeIconwrap>
                        </LocalizeContentboxInner>
                      </Anchor>
                  ))}
              </LocalizeContentbox>
            </Col>
          </Row>
          {data?.buttons?.map(({ id, path, content, ...rest }) => (
            <Button className="btn-learn-more"  key={id} path={path} {...rest}>
              {content}
            </Button>
          ))}
        </Container>
      </LocalizeInner>
    </LocalizeWrapper>
       </React.Suspense>
       )
  );
})
export default LocalizeGrid;
