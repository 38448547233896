import styled, {css, themeGet, device } from "@styled";
import benefitBeforeBg from "@data/images/bg/benefitsBeforeBg.png"

export const LocalizeWrapper = styled.section`
  padding: 60px 0;
  ${device.large}{
    padding: 100px 0;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
    &#empowering-global-voice{
      .section-title{
       ${device.large}{
          margin-bottom:80px !important;
        }
      }
    }
    .container{
      position:relative;
      &::before{
        content:'';
        position:absolute;
        top:-50px;
        left:10px;
        z-index:-1;
        width:210px;
        height:250px;
        background:url(${benefitBeforeBg}) no-repeat;
        background-size:contain;
        ${device.large}{
          top:-80px;
          left:-100px;
          width:288px;
          height:310px;
        }
      }
    }
  `}
`;

export const LocalizeInner = styled.div`
  .section-title{
    ${device.xxlarge} {
      margin-bottom:50px;
    }
      p{
      color:#333;
      }
  }
`;
export const LocalizeContentbox = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(4, 1fr);
  }
  a {
    display: inherit;
    &:hover {
      color: inherit;
    }
  }
`;
export const LocalizeContentboxInner = styled.div`
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  perspective: 1000px;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(0 0 0 / 30%);
    transform: translateY(-3px);
  }
`;

export const LocalizeIconwrap = styled.div`
  padding: 30px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ${themeGet("transition")};
  backface-visibility: hidden;
  position: relative;
  background: #fff;
  height: 100%;
  z-index: 2;
  width: 100%;
  h4 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    ${device.large} {
      font-size: 22px;
    }
  }
  p{
    color:#343434;
    font-size:15px;
    font-weight:500;
  }
  .gatsby-image-wrapper {
    height: 60px;
    img {
      object-fit: contain !important;
    }
  }
`;
